import React from "react";
import { Button, Col, Container, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import { useEffect, useState } from "react";

import LoadingScreen from "./Loading/LoadingScreen";

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(true);
  }, [setImageLoaded]);

  return (
    <Container className="home text-white" fluid>
      {!imageLoaded && <LoadingScreen />}
      <Button
        className="btn-goto-check-out"
        onClick={() => (window.location.href = "buy-ticket")}
      >
        BELI TIKET DISINI!
      </Button>
      {/* <Button
        className="btn-goto-wa"
        onClick={() =>
          getLink(
            `https://api.whatsapp.com/send/?phone=${csNumber}&text=Halo+Admin+Soul%2C+Saya+ingin+bertanya+tentang+Soul&type=phone_number&app_absent=0`
          )
        }
      /> */}
      <Col className="w-100 justify-content-center d-flex">
        <AnimationTitles size="32px" weight="700">
          <motion.span style={{ fontWeight: "Bold" }}>
            <Col className="text-center">
              <Image
                className="img-hero"
                src={require("../images/soul/hero-banner.webp")}
                loading="lazy"
              />
            </Col>
          </motion.span>
        </AnimationTitles>
      </Col>
      <Col className="text-center mb-5">
        <AnimationTitles>
          <motion.span>
            <h6
              style={{
                fontSize: "0.9rem",
                fontWeight: "400",
                color: "rgba(255, 227, 120, 1)",
              }}
            >
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ marginRight: "5px" }}
              />
              SATURDAY, 12 APRIL 2025
            </h6>
            <h6
              style={{ fontSize: "0.9rem", fontWeight: "400" }}
              className="mb-5"
            >
              <FontAwesomeIcon icon={faMapPin} style={{ marginRight: "5px" }} />
              THE KASABLANKA HALL, SOUTH JAKARTA
            </h6>
            <Image
              className="w-100"
              src={require("../images/soul/venue.webp")}
              loading="lazy"
            />
          </motion.span>
        </AnimationTitles>
      </Col>
      <Col className="w-100">
        <Image
          className="w-100 mb-4"
          src={require("../images/soul/get-experience.png")}
          loading="lazy"
        />
      </Col>
      <Image
        className="w-100 mb-4"
        src={require("../images/soul/afgan-expr.webp")}
        loading="lazy"
      />
      <Col className="px-5">
        <Image
          className="w-100 mb-4"
          src={require("../images/soul/soul-logo-new.webp")}
          loading="lazy"
        />
      </Col>

      <AnimationTitles>
        <motion.span>
          <Col className="text-center">
            <Image
              className="img-seating-plan mb-4"
              src={require("../images/soul/seating-plan.png")}
              loading="lazy"
            />

            <Image
              className="img-seating-plan mb-4"
              src={require("../images/soul/benefit.png")}
              loading="lazy"
            />
          </Col>
        </motion.span>
      </AnimationTitles>
      <Col className="w-100 text-center mb-2">
        <Image
          className="img-seating-plan mb-4"
          src={require("../images/soul/rundown.png")}
          loading="lazy"
        />
      </Col>
      <Col className="w-100 text-center mb-2">
        <Image
          className="img-seating-plan mb-4"
          src={require("../images/soul/playlist.png")}
          loading="lazy"
        />
      </Col>
      <iframe
        style={{ borderRadius: "12px", marginBottom: "20px" }}
        src="https://open.spotify.com/embed/playlist/2Sv7INPvsSRi1swiP73jAE?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        title="Spotify Playlist"
      ></iframe>
      <Col className="w-100 text-center mb-2">
        <Image
          className="img-seating-plan mb-4"
          src={require("../images/soul/gallery-title.png")}
          loading="lazy"
        />
      </Col>
      <Col className="w-100 mb-4">
        <iframe
          width="100%"
          height="230"
          src="https://www.youtube.com/embed/u2zKqCgJg_0"
          title="AFTER MOVIE SOUL INTIMATE CONCERT 2.0 - THE KASABLANKA HALL"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <br />
        <br />
        <iframe
          width="100%"
          height="230"
          src="https://www.youtube.com/embed/LhvM6DVl24g"
          title="Ekspectanica Special Intimate 2023 - Official Aftermovie"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <br />
        <br />
        <iframe
          width="100%"
          height="230"
          src="https://www.youtube.com/embed/ZUJqgPkEPyw"
          title="AFTER MOVIE SOUL INTIMATE CONCERT 3.0 - The Kasablanka Hall"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </Col>
      <Col className="w-100 text-center mb-1">
        <Image
          className="img-seating-plan mb-1"
          src={require("../images/soul/event-galerry-title.png")}
          loading="lazy"
        />
      </Col>
      <Col className="d-flex" style={{ overflow: "scroll" }}>
        <Image
          className="img-seating-plan mb-4"
          style={{ marginRight: "20px" }}
          src={require("../images/soul/gal-1.webp")}
          loading="lazy"
        />
        <Image
          className="img-seating-plan mb-4"
          style={{ marginRight: "20px" }}
          src={require("../images/soul/gal-2.webp")}
          loading="lazy"
        />
        <Image
          className="img-seating-plan mb-4"
          style={{ marginRight: "20px" }}
          src={require("../images/soul/gal-3.webp")}
          loading="lazy"
        />
        <Image
          className="img-seating-plan mb-4"
          style={{ marginRight: "20px" }}
          src={require("../images/soul/gal-4.webp")}
          loading="lazy"
        />
      </Col>
      <Col className="w-100 text-center mb-5">
        <Image
          className="img-seating-plan mb-1"
          src={require("../images/soul/promoted-event.png")}
          loading="lazy"
        />
      </Col>
      <h6 className="text-center mt-5 mb-5 text-white">
        Supported by Pocketsid © 2025
      </h6>
    </Container>
  );
};

export default Home;
