import React from "react";
import "./LoadingScreen.css";

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <p className="text-white">Tunggu Sebentar...</p>
      <p className="text-white">Kamu Akan Diarahkan Ke Halaman Pembayaran...</p>
    </div>
  );
};

export default LoadingScreen;
