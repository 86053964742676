import React, { useCallback } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormSelect } from "../components/widget/form-select/FormSelect";
import {
  faEye,
  faEyeSlash,
  faMinus,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import { useEffect, useRef, useState } from "react";
import {
  formatNumberWithout,
  formatPhoneNumber,
  getLink,
  validateEmail,
} from "../helper";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { admin_fee } from "../components/utils";

const arrayColor = [
  { name: "CAT 1", color: "#7E0429" },
  { name: "CAT 2", color: "#136B35" },
  { name: "CAT 3", color: "#AF904A" },
  { name: "CAT 4", color: "#86746A" },
];

const BuyTicket = (props) => {
  const [count, setCount] = useState(1);
  const [mailStatus, setMailStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [mailAvail, setMailAvail] = useState(false);
  const [csNumber, setCsNumber] = useState("08");
  const [disableMail, setDisableMail] = useState(false);
  const [disableCheckout, setDisableBtnCheckout] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [listVariation, setListVariation] = useState([]);
  const [cityVal, setCityVal] = useState({});
  const [cityName, setCityName] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const section3Ref = useRef(null);
  const section2Ref = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [showModalVariation, setShowModalVariation] = useState(null);

  const [cityError, setCityError] = useState("Masukkan 3 huruf");

  const preventEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleCityInput = (e) => {
    const input = e.target.value;

    // Update city name and list
    setCityName(input);
    if (input.length >= 3) {
      checkCity(input);
    } else {
      setCityList([]);
      setCityVal({});
    }

    // Update error message or clear it
    const charsLeft = Math.max(3 - input.length, 0);
    if (charsLeft > 0) {
      setCityError(
        <>
          Masukkan <span style={{ color: "red" }}>{charsLeft}</span> huruf lagi
        </>
      );
    } else {
      setCityError("");
    }
  };

  const getColorByName = (name) => {
    const item = arrayColor.find((colorItem) => colorItem.name === name);
    return item ? item.color : null; // Jika tidak ditemukan, return null
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const addData = (ticketId, variationName, avail, action) => {
    setListVariation((prevList) =>
      prevList.map((item) => {
        if (item.ticket_id === ticketId) {
          return {
            ...item,
            variation: item.variation.map((variation) => {
              if (variation.name === variationName) {
                let newQty = variation.qty ? variation.qty : 0;

                if (action === "increase" && newQty < 10 && newQty < avail) {
                  newQty += 1;
                } else if (action === "decrease") {
                  newQty -= 1;
                }

                if (newQty <= 0) {
                  const { qty, ...rest } = variation;
                  return rest;
                }

                return { ...variation, qty: newQty };
              }
              return variation;
            }),
          };
        }
        return item;
      })
    );
  };

  const calculateTotals = () => {
    let totalPrice = 0;
    let totalQty = 0;

    listVariation.forEach((item) => {
      item.variation.forEach((variation) => {
        if (variation.qty) {
          // Pastikan qty ada sebelum menghitung
          totalQty += variation.qty;
          totalPrice += variation.price * variation.qty;
        }
      });
    });

    return { totalPrice, totalQty };
  };

  const validateForm = () => {
    const newErrors = {};

    const email = document.getElementById("email").value;
    const name = document.getElementById("name")?.value;
    const phone = document.getElementById("phone")?.value;
    const password = document.getElementById("password")?.value;

    if (!validateEmail(email)) {
      newErrors.email = "Format email salah";
      scrollToSection(section3Ref);
    }

    if (mailAvail) {
      if (cityVal.name === undefined) {
        newErrors.city = "Pilih Salah Satu Kota Terlabih Dahulu";
        scrollToSection(section3Ref);
      }

      if (!name?.trim()) {
        newErrors.name = "Nama tidak boleh kosong";
        scrollToSection(section3Ref);
      }

      if (!phone?.trim()) {
        newErrors.phone = "No. WhatsApp tidak boleh kosong";
        scrollToSection(section3Ref);
      } else if (!/^\d+$/.test(phone)) {
        newErrors.phone = "No. WhatsApp harus berupa angka";
        scrollToSection(section3Ref);
      } else if (phone.length < 10) {
        newErrors.phone = "No. WhatsApp harus minimal 10 digit";
        scrollToSection(section3Ref);
      }

      if (!password?.trim()) {
        newErrors.password = "Password tidak boleh kosong";
        scrollToSection(section3Ref);
      } else if (password.length < 6) {
        newErrors.password = "Password harus minimal 6 karakter";
        scrollToSection(section3Ref);
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePay = async (e, vars) => {
    e.preventDefault();
    if (!mailStatus) {
      alert("Masukkan Email dan Klik Lanjutkan");
      return;
    }

    if (calculateTotals().totalPrice === 0 || calculateTotals().totalQty <= 0) {
      alert("Pilih Kategori Tiket Terlebih Dahulu");
      return;
    }

    if (!validateForm()) return;

    if (paymentMethod === "") {
      alert("Pilih Metode Pembayaran Terlebih Dahulu");
      return;
    }

    setDisableBtnCheckout(true);
    setLoading(true);
    const params = {
      variation: listVariation
        .map((item) => ({
          ...item,
          variation: item.variation.filter((variation) => variation.qty > 0),
        }))
        .filter((item) => item.variation.length > 0),
      email: document.getElementById("email").value,
      kupon: "",
      city: cityVal,
      source: id ?? "meta",
      payment_method: paymentMethod,
    };

    if (mailAvail) {
      params.phone = formatPhoneNumber(document.getElementById("phone").value);
      params.name = document.getElementById("name").value;
      params.password = document.getElementById("password").value;
      params.gender = document.getElementById("gender").value;
      params.dob = document.getElementById("dob").value;
    } else {
      params.phone = 9999999999;
      params.name = "name";
      params.password = "password01";
    }

    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/booking_soul`,
        {
          headers: {
            "Content-Type": "application/json",
            token: process.env.REACT_APP_API_KEY,
          },
          method: "post",
          data: JSON.stringify(params),
        }
      );
      if (response.data.status) {
        if (response.data.status) {
          setDisableBtnCheckout(true);
          setTimeout(() => {
            setLoading(false);
            window.location.href = response.data.data.payment_cc;
          }, 3000);
        }
      } else {
        setDisableBtnCheckout(false);
        setLoading(false);
        alert(response.data.message);
        throw new Error("Request failed");
      }
    } catch (error) {
      setDisableBtnCheckout(false);
      setLoading(false);
    }
  };

  const getDiscount = useCallback(async () => {
    try {
      const response = await axios(`${process.env.REACT_APP_API_URL}/d__soul`, {
        headers: {
          "Content-Type": "application/json",
          token: process.env.REACT_APP_API_KEY,
        },
        method: "post",
        data: "",
      });

      if (response.data.status) {
        setCsNumber(response.data.data.cs___n);
        // setFlashSaleStt(response.data.data.s___m);
        const transformedData = response.data.data?.v___e.map((item) => ({
          ticket_id: item.ticket_content.ticket_id,
          title: item.ticket_content.title,
          start_price: item.f_price,
          variation: filterVariations(JSON.parse(item.variations)),
        }));

        setListVariation(transformedData);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const filterVariations = (variations) => {
    const now = new Date(); // Waktu saat ini

    return variations.filter((variation) => {
      const startDate = new Date(variation.variation_startdate);
      const endDate = new Date(variation.variation_enddate);

      return (
        variation.status === "active" && startDate <= now && now <= endDate
      );
    });
  };

  const checkMail = async () => {
    const email = document.getElementById("email").value;
    const valid = validateEmail(email);
    if (valid.isValid) {
      const datas = {
        email: email,
      };

      try {
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/check_email`,
          {
            headers: {
              "Content-Type": "application/json",
              token: process.env.REACT_APP_API_KEY,
            },
            method: "post",
            data: JSON.stringify(datas),
          }
        );
        if (response.data.status) {
          setDisableMail(true);
          if (response.data.message === "Email Available") {
            setMailStatus(true);
            setMailAvail(false);
          } else {
            setMailStatus(true);
            setMailAvail(true);
          }
        } else {
          alert("Email not available");
        }
      } catch (error) {}
    } else {
      alert(valid.error);
    }
  };

  const checkCity = async (city) => {
    setCityVal({});
    setCityName(city);
    if (city.length >= 3) {
      const datas = {
        city: city,
      };

      try {
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/check_city`,
          {
            headers: {
              "Content-Type": "application/json",
              token: process.env.REACT_APP_API_KEY,
            },
            method: "post",
            data: JSON.stringify(datas),
          }
        );
        if (response.data.status) {
          if (response.data.data.length > 0) {
            setCityList(response.data.data);
          } else {
            setCityList([]);
            setCityError(
              <span style={{ color: "red" }}>Kota tidak ditemukan</span>
            );
          }
        } else {
          alert("City not available");
        }
      } catch (error) {
        setCityError("Terjadi kesalahan, coba lagi");
      }
    }
  };

  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        setShowModal(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (count >= 14) return;

    const interval = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 100);

    return () => clearInterval(interval);
  }, [count, showModal]);

  useEffect(() => {
    getDiscount();
  }, [getDiscount]);

  return (
    <Container fluid className="buy-ticket">
      <Modal
        show={showModalVariation ? true : false}
        onHide={() => setShowModalVariation(null)}
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h5 className="text-black fw-bold">{showModalVariation}</h5>
        </Modal.Header>
        <Modal.Body>
          {listVariation
            ?.filter((e) => e.title === showModalVariation)
            ?.map((item, index) => {
              return item?.variation && item.variation.length > 0
                ? item?.variation
                    .filter(
                      (variation) =>
                        variation && variation?.ticket_available > 0
                    )
                    .map((items, indexs) => {
                      return (
                        <Col
                          className="mb-2 px-2 col-box-variation-ticket py-2"
                          style={{ position: "relative" }}
                          key={indexs}
                        >
                          <Col className="d-flex">
                            <div
                              style={{
                                width: "20px",
                                marginTop: "3px",
                                marginRight: "10px",
                                height: "20px",
                                backgroundColor: getColorByName(item?.title),
                              }}
                            />
                            <h5 className="text-black fw-bold">
                              {items?.name}
                            </h5>
                          </Col>
                          <h6 className="text-black mb-2">
                            For {items?.allocated_person} Person
                          </h6>
                          <Row className="align-items-center">
                            <Col xs={6}>
                              <h4 className="text-black fw-light">
                                Rp
                                {formatNumberWithout(
                                  String(items?.price) || "0"
                                )}
                              </h4>
                            </Col>
                            <Col xs={6} className="bg-ticket-tot w-50">
                              <Row
                                xs={6}
                                className="day-2-active w-100 align-items-center justify-content-between"
                                style={{ cursor: "pointer" }}
                              >
                                <Col
                                  xs={3}
                                  className="bg-ticket-tot"
                                  onClick={() =>
                                    addData(
                                      item?.ticket_id,
                                      items?.name,
                                      items?.ticket_available,
                                      "decrease"
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faMinus} />
                                </Col>
                                <Col
                                  xs={3}
                                  className="bg-ticket-txtot justify-content-end align-items-end d-flex-col"
                                >
                                  <h6 className="bg-ticket-txtot">
                                    {items?.qty || 0}
                                  </h6>
                                </Col>
                                <Col
                                  xs={3}
                                  className="bg-ticket-tot"
                                  style={{ zIndex: 999 }}
                                  onClick={() =>
                                    addData(
                                      item?.ticket_id,
                                      items?.name,
                                      items?.ticket_available,
                                      "increase"
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {items?.on_sale === "1" && (
                            <div className="sale-ribbon">
                              <span className="span-sale-ribbon">SALE</span>
                            </div>
                          )}
                        </Col>
                      );
                    })
                : "";
            })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-100 btn-check-ticket"
            onClick={() => [
              setShowModalVariation(null),
              scrollToSection(section2Ref),
            ]}
          >
            Lanjutkan Pembayaran
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        className="btn-goto-wa"
        onClick={() =>
          getLink(
            `https://api.whatsapp.com/send/?phone=${csNumber}&text=Halo+Admin+Soul%2C+Saya+ingin+bertanya+tentang+Soul&type=phone_number&app_absent=0`
          )
        }
      />
      <Col className="w-100 text-center mb-2">
        <Image
          className="img-icon-buy-now mb-4"
          src={require("../images/soul/buy-tickets.png")}
          loading="lazy"
        />
        <Image
          className="img-seating-plan mb-4"
          src={require("../images/soul/seating-plan.png")}
          loading="lazy"
        />
      </Col>
      <Col className="ticket-box" ref={section3Ref}>
        {listVariation?.map((item, index) => {
          return (
            <Col
              className="mb-2 px-2 col-box-variation py-2"
              style={{ position: "relative" }}
              key={index}
            >
              <Col className="d-flex mb-1">
                <div
                  style={{
                    width: "20px",
                    marginTop: "3px",
                    marginRight: "10px",
                    height: "20px",
                    backgroundColor: getColorByName(item?.title),
                  }}
                />
                <h5 className="text-white fw-bold">{item?.title}</h5>
              </Col>

              <Row className="align-items-center">
                <Col xs={6} className="align-items-center d-flex ">
                  <h6 className="text-white mb-2">
                    Starts:
                    <br /> Rp{formatNumberWithout(item?.start_price)}
                  </h6>
                </Col>
                <Col
                  xs={6}
                  className="bg-ticket-tot w-50 justify-content-end d-flex"
                >
                  <Button
                    onClick={() => setShowModalVariation(item?.title)}
                    className="btn-check-ticket"
                  >
                    Lihat Tiket
                  </Button>
                </Col>
              </Row>
            </Col>
          );
        })}
        <Col ref={section2Ref}>
          <AnimationTitles>
            <motion.span>
              <Col>
                <Image
                  className="w-100 mb-2 mt-5"
                  src={require("../images/soul/detail-buyyer.png")}
                  loading="lazy"
                />
              </Col>
            </motion.span>
          </AnimationTitles>
        </Col>
        <Form onSubmit={handlePay}>
          <InputGroup className="mb-1">
            <Form.Control
              type="email"
              id="email"
              className="input-email"
              placeholder="Masukkan e-mail"
              disabled={disableMail}
              required
              onKeyDown={preventEnter}
            />
            <Button
              className="btn-email_ver"
              onClick={() =>
                disableMail
                  ? [
                      setDisableMail(false),
                      (document.getElementById("email").value = ""),
                      setMailStatus(false),
                      setMailAvail(false),
                    ]
                  : checkMail()
              }
            >
              {disableMail ? "Ganti Email" : "Lanjutkan"}
            </Button>
          </InputGroup>
          {mailStatus && (
            <h6 className="text-warning">Email Berhasil Divalidasi !</h6>
          )}
          <Col>
            {mailAvail && (
              <>
                <Row
                  className="d-flex justify-content-center align-item-center mt-4 px-3 mb-3"
                  ref={section3Ref}
                >
                  <Col xs={1} className="lines" />
                  <Col
                    xs={10}
                    className="text-center "
                    style={{ fontSize: "0.8rem" }}
                  >
                    Buat Akun
                    <Image
                      src={require("../images/mobile/pocketsid.webp")}
                      alt="Pocketsid Logo"
                      style={{
                        width: "auto",
                        height: "13px",
                        marginLeft: "10px",
                        marginTop: "-0.2rem",
                      }}
                      loading="lazy"
                    />
                  </Col>
                  <Col xs={1} className="lines" />
                </Row>
                <Form.Label>Nama</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  className="input-regis"
                  placeholder="Masukkan Nama"
                  isInvalid={!!errors.name}
                  required
                  onKeyDown={preventEnter}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>

                <Form.Label>Jenis Kelamin</Form.Label>
                <Form.Control
                  id="gender"
                  className="input-regis"
                  placeholder="Pilih Jenis Kelamin"
                  as={FormSelect}
                  options={[
                    { value: "male", label: "Laki Laki" },
                    { value: "female", label: "Perempuan" },
                  ]}
                  required
                  onKeyDown={preventEnter}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.gender}
                </Form.Control.Feedback>

                <Form.Label>Tanggal Lahir</Form.Label>
                <Form.Control
                  type="date"
                  id="dob"
                  className="input-regis"
                  placeholder="Tanggal Lahir"
                  isInvalid={!!errors.dob}
                  required
                  onKeyDown={preventEnter}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.dob}
                </Form.Control.Feedback>

                <Form.Label>No. WhatsApp</Form.Label>
                <Form.Control
                  type="number"
                  id="phone"
                  className="input-regis"
                  placeholder="Masukkan No. WhatsApp"
                  isInvalid={!!errors.phone}
                  required
                  onKeyDown={preventEnter}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>

                <Form.Label className="text-white">Kota Asal</Form.Label>
                <Form.Text
                  className="text-danger"
                  style={{ marginLeft: "5px" }}
                >
                  {cityError}
                </Form.Text>
                <InputGroup className="">
                  <Form.Control
                    type="text"
                    id="city"
                    className="input-city"
                    onChange={handleCityInput}
                    value={cityName}
                    isInvalid={!!errors.city}
                    placeholder="Cari Nama Kota"
                    required
                    onKeyDown={(e) => {
                      preventEnter(e);
                      if (/[\d]/.test(e.key)) {
                        e.preventDefault(); // Prevents entering numbers
                      }
                    }}
                    onBlur={() => {
                      if (cityList.length === 0 || !cityVal.name) {
                        setErrors({ ...errors, city: "Pilih Kota" });
                      }
                    }}
                  />
                  <Button className="btn-city_ver">
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>

                {cityList.length > 0 && (
                  <Col
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginBottom: "10px",
                    }}
                  >
                    <Col
                      style={{
                        borderRadius: "30px",
                        color: "black",
                        fontWeight: "700",
                        backgroundColor: "white",
                        paddingBlock: "10px",
                        paddingInline: "10px",
                      }}
                    >
                      Pilih Kota
                    </Col>
                    {cityList?.map((item, index) => {
                      const isLast = index === cityList.length - 1;
                      return (
                        <React.Fragment key={item.id || item.name || index}>
                          <Col
                            onClick={() => [
                              setCityName(item.name),
                              setCityList([]),
                              setCityVal(item),
                              setCityError(""),
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                city: undefined,
                              })),
                            ]}
                            key={index}
                            style={{
                              borderBottom: "1px black",
                              borderRadius: "20px",
                              color: "black",
                              backgroundColor: "white",
                              paddingBlock: "10px",
                              paddingInline: "10px",
                            }}
                          >
                            {item.name}
                          </Col>
                          {!isLast && <Col className="lines-black" />}
                        </React.Fragment>
                      );
                    })}
                  </Col>
                )}

                <Form.Label> Buat Password</Form.Label>
                <InputGroup className="">
                  <Form.Control
                    type={passwordShow ? "password" : "text"}
                    id="password"
                    className="input-regis"
                    placeholder="Buat Password"
                    isInvalid={!!errors.password}
                    required
                    onKeyDown={preventEnter}
                  />
                  <Button
                    className="btn-city_ver"
                    onClick={() => setPasswordShow(!passwordShow)}
                  >
                    <FontAwesomeIcon
                      icon={!passwordShow ? faEye : faEyeSlash}
                    />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
                <Row className="d-flex justify-content-center align-item-center mt-4 px-3 mb-3">
                  <Col xs={1} className="lines" />
                  <Col
                    xs={10}
                    className="text-center "
                    style={{ fontSize: "0.8rem" }}
                  >
                    Kamu Bisa Gunakan Email dan Password tersebut untuk login ke
                    pockets.id
                  </Col>
                  <Col xs={1} className="lines" />
                </Row>
              </>
            )}
            <AnimationTitles>
              <motion.span>
                <Col>
                  <Image
                    className="w-100 mb-2 mt-5"
                    src={require("../images/soul/payment-method.png")}
                    loading="lazy"
                  />
                </Col>
              </motion.span>
            </AnimationTitles>
            <Col className="ticket-box-payment mb-4">
              <Col
                className="payment-select d-flex align-items-center"
                onClick={() => setPaymentMethod("QRIS")}
              >
                <Form.Check
                  value={"QRIS"}
                  checked={paymentMethod === "QRIS" && true}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mx-2"
                  type="radio"
                  id="payment_method"
                  name="payment_method"
                />
                <Image
                  src={require("../images/mobile/bank/QRIS-1.png")}
                  style={{ width: "20%", height: "100%", marginRight: "10px" }}
                />
                <h6 className="fw-lighter text-black">QRIS</h6>
              </Col>
              <Col
                className="payment-select d-flex align-items-center"
                onClick={() => setPaymentMethod("BCA")}
              >
                <Form.Check
                  value={"BCA"}
                  checked={paymentMethod === "BCA" && true}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mx-2"
                  type="radio"
                  id="payment_method"
                  name="payment_method"
                />
                <Image
                  src={require("../images/mobile/bank/BCA-1.png")}
                  style={{
                    width: "20%",
                    marginRight: "10px",
                  }}
                />
                <h6 className="fw-lighter text-black">BCA VIRTUAL ACCOUNT</h6>
              </Col>
              <Col
                className="payment-select d-flex align-items-center"
                onClick={() => setPaymentMethod("BNI")}
              >
                <Form.Check
                  value={"BNI"}
                  checked={paymentMethod === "BNI" && true}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mx-2"
                  type="radio"
                  id="payment_method"
                  name="payment_method"
                />
                <Image
                  src={require("../images/mobile/bank/BNI-1.png")}
                  style={{
                    width: "20%",
                    height: "100%",
                    marginRight: "10px",
                  }}
                />
                <h6 className="fw-lighter text-black">BNI VIRTUAL ACCOUNT</h6>
              </Col>
              <Col
                className="payment-select d-flex align-items-center"
                onClick={() => setPaymentMethod("BSI")}
              >
                <Form.Check
                  value={"BSI"}
                  checked={paymentMethod === "BSI" && true}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mx-2"
                  type="radio"
                  id="payment_method"
                  name="payment_method"
                />
                <Image
                  src={require("../images/mobile/bank/BSI-1.png")}
                  style={{
                    width: "20%",
                    height: "100%",
                    marginRight: "10px",
                  }}
                />
                <h6 className="fw-lighter text-black">BSI VIRTUAL ACCOUNT</h6>
              </Col>
              <Col
                className="payment-select d-flex align-items-center"
                onClick={() => setPaymentMethod("MANDIRI")}
              >
                <Form.Check
                  value={"MANDIRI"}
                  checked={paymentMethod === "MANDIRI" && true}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mx-2"
                  type="radio"
                  id="payment_method"
                  name="payment_method"
                />
                <Image
                  src={require("../images/mobile/bank/MANDIRI-1.png")}
                  style={{
                    width: "20%",
                    height: "100%",
                    marginRight: "10px",
                  }}
                />
                <h6 className="fw-lighter text-black">
                  MANDIRI VIRTUAL ACCOUNT
                </h6>
              </Col>
              <Col
                className="payment-select d-flex align-items-center"
                onClick={() => setPaymentMethod("CREDIT_CARD")}
              >
                <Form.Check
                  value={"CREDIT_CARD"}
                  checked={paymentMethod === "CREDIT_CARD" && true}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mx-2"
                  type="radio"
                  id="payment_method"
                  name="payment_method"
                />
                <Image
                  src={require("../images/mobile/bank/CREDIT_CARD-1.png")}
                  style={{
                    width: "20%",
                    height: "100%",
                    marginRight: "10px",
                  }}
                />
                <h6 className="fw-lighter text-black">CREDIT CARD</h6>
              </Col>
            </Col>
            <Col className="col-box-variation px-3 py-2">
              {listVariation.map((item, index) => {
                return item?.variation && item.variation.length > 0
                  ? item?.variation
                      .filter(
                        (variation) =>
                          variation &&
                          variation?.ticket_available > 0 &&
                          variation?.qty >= 1
                      )
                      .map((items, indexs) => {
                        return (
                          <Row key={indexs} className="mb-2">
                            <Col xs={8} className="text-start">
                              {item?.title} - {items?.name} ({items?.qty}x)
                            </Col>
                            <Col xs={4} className="text-end">
                              {formatNumberWithout(
                                String(items?.qty * items?.price || 0)
                              )}
                            </Col>
                          </Row>
                        );
                      })
                  : "";
              })}
              <Row className="mb-3">
                <Col xs={6} className="text-start">
                  Admin Fee{" "}
                  <span
                    style={{ color: "rgb(32, 120, 35)", fontWeight: 800 }}
                  ></span>
                </Col>
                <Col xs={6} className="text-end">
                  {calculateTotals().totalPrice === 0
                    ? 0
                    : formatNumberWithout(
                        String(
                          (calculateTotals().totalPrice * admin_fee) / 100 || 0
                        )
                      )}
                </Col>
              </Row>
              <Col className="mb-2 col-box-total-payment d-flex align-items-center justify-content-between">
                <Col xs={6} className="text-start tx-total-ticket">
                  Hanya bayar
                </Col>
                <Col xs={6} className="text-end tx-total-ticket">
                  {formatNumberWithout(
                    String(
                      calculateTotals().totalPrice +
                        calculateTotals().totalPrice * (admin_fee / 100)
                    )
                  )}
                </Col>
              </Col>
            </Col>
            <Row className="d-flex justify-content-center align-item-center mt-4 px-3 mb-3">
              <Col xs={1} className="lines" />
              <Col
                xs={10}
                className="text-center text-white"
                style={{ fontSize: "0.8rem" }}
              >
                Dapatkan Eticket di emailmu atau akun pockets.id kamu
              </Col>
              <Col xs={1} className="lines" />
            </Row>
            <Row>
              <Col xs={12} className="text-end" style={{ marginTop: "14px" }}>
                <Button
                  className="btn-buy-now-ticket text-white"
                  role="button"
                  type="submit"
                  disabled={disableCheckout}
                >
                  LANJUTKAN PEMBAYARAN
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
        {loading && (
          <div className="loader-overlay">
            <Spinner animation="border" variant="light" />
          </div>
        )}
        <h6 className="text-center mt-5 text-white">
          Supported by Pocketsid © 2025
        </h6>
      </Col>
    </Container>
  );
};

export default BuyTicket;
