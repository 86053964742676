const artis_day1 = [
  {
    name: "dewa 19",
    row: 4,
    img: "dewa.webp",
  },
  {
    name: "bernadya",
    row: 4,
    img: "bernadya.webp",
  },
  {
    name: "sal priadi",
    row: 4,
    img: "sal.webp",
  },
  {
    name: "hindia",
    row: 3,
    img: "hindia.webp",
  },
  {
    name: "pee wee gaskin",
    row: 3,
    img: "peewe.webp",
  },
  {
    name: "teddy",
    row: 3,
    img: "teddy.webp",
  },
  {
    name: "aftershine",
    row: 3,
    img: "aftershine.webp",
  },
];

const artis_day2 = [
  {
    name: "Juicy Luicy",
    row: 4,
    img: "juicy.webp",
  },
  {
    name: "the masiv",
    row: 4,
    img: "dmasiv.webp",
  },
  {
    name: "nidji",
    row: 4,
    img: "nidji.webp",
  },
  {
    name: "raisa",
    row: 3,
    img: "raisa.webp",
  },
  {
    name: "arash",
    row: 3,
    img: "arash.webp",
  },
  {
    name: "cangcuter",
    row: 3,
    img: "cangcuter.webp",
  },
  {
    name: "Denny Caknan",
    row: 3,
    img: "denny.webp",
  },
];

const list_buyyer = [
  { name: "Andi", qyt: 3 },
  { name: "Budi", qyt: 2 },
  { name: "Citra", qyt: 4 },
  { name: "Dewi", qyt: 1 },
  { name: "Eko", qyt: 3 },
];

const day1_var = [
  {
    id: 17,
    name: "Pre Sale 2 - Sendiri",
    real_price: 149,
    discount: null,
    price: 149,
    allocated_person: "1",
  },
  {
    id: 17,
    name: "Pre Sale 2 - Berdua",
    real_price: 298,
    price: 278,
    allocated_person: "2",
    discount: 20000,
  },
  {
    id: 17,
    name: "Pre Sale 2 - Berempat",
    real_price: 596,
    price: 516,
    allocated_person: "4",
    discount: 80000,
  },
];

const day2_var = [
  {
    id: 18,
    name: "Pre Sale 2 - Sendiri",
    real_price: 149,
    discount: null,
    price: 149,
    allocated_person: "1",
  },
  {
    id: 18,
    name: "Pre Sale 2 - Berdua",
    real_price: 298,
    price: 278,
    allocated_person: "2",
    discount: 20000,
  },
  {
    id: 18,
    name: "Pre Sale 2 - Berempat",
    real_price: 596,
    price: 516,
    allocated_person: "4",
    discount: 80000,
  },
];

const pass_2days = [
  {
    id: 19,
    name: "Pre Sale 2 - Sendiri",
    real_price: 259,
    discount: null,
    price: 259,
    allocated_person: "1",
  },
  {
    id: 19,
    name: "Pre Sale 2 - Berdua",
    real_price: 518,
    price: 498,
    allocated_person: "2",
    discount: 20000,
  },
  {
    id: 19,
    name: "Pre Sale 2 - Berempat",
    real_price: 1036,
    price: 956,
    allocated_person: "4",
    discount: 80000,
  },
];

const discount_val = 20;
const discount_val_email = 5;
const discount_val_kupon = 0;
const admin_fee = 5.5;

export {
  admin_fee,
  artis_day1,
  artis_day2,
  discount_val_kupon,
  list_buyyer,
  day1_var,
  day2_var,
  pass_2days,
  discount_val,
  discount_val_email,
};
